import React, { useEffect, useState, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WrapJsCookies from "./Cookies";
import TransferRequests from "./TransferRequests";

function Account({isAccountOpen, ShowAccount, triggerToast, loggedToken, setLoggedToken})
{
    const [showAccountData, setShowAccountData]     = useState(false);
    const [beforeAccountData, setBeforeAccountData] = useState('Loading...');
    const [fetchedAccount, setFetchedAccount]       = useState(false);
    const [accountData, setAccountData]             = useState([]);

    const [newFirstName, setNewFirstName]           = useState('');
    const [newLastName, setNewLastName]             = useState('');
    const [newPhoneNumber, setNewPhoneNumber]       = useState('');


    // load account data
    const LoadAccountData = useCallback(async () => 
    {
        // fetch the account data

        let accountContainer = 
        {
            authToken: loggedToken
        };

        let LoadAccount = await TransferRequests('accountdata', accountContainer);
        setFetchedAccount(true);

        if(LoadAccount.isValidJson === true)
        {
            // check the data
            if(LoadAccount.responseBody[0] === true)
            {
                // data values
                let MyAccountData                     = LoadAccount.responseBody[1][0];

                // set each data here
                setAccountData([
                    { name: [MyAccountData.firstName + " " + MyAccountData.lastName, 'user', 'Full Name',true] },
                    { phone: [MyAccountData.phoneNumber, 'phone', 'Phone Number',true] },
                    { email: [MyAccountData.emailAddress, 'envelope', 'Email Address',false] },
                    { verified: [MyAccountData.emailVerified, 'check', 'Email Verified',false] },
                    { status: [MyAccountData.accounStatus, 'flag', 'Account Status',false] },
                    { lastLogin: [MyAccountData.lastLogin, 'user-plus', 'Last Login',false] },
                    { dateJoined: [MyAccountData.dateCreated, 'calendar-alt', 'Date Joined',false] },
                    { activeThreads: [MyAccountData.activeThreads, 'pen-to-square', 'Active Threads',false] },
                    { deletedThreads: [MyAccountData.deletedThreads, 'triangle-exclamation', 'Deleted Threads',false] }
                ]);
                

                setShowAccountData(true);
            }
            else
            {
                triggerToast(LoadAccount.responseBody[1]);
                setBeforeAccountData(LoadAccount.responseBody[1]);
            }
        }
        else
        {
            triggerToast("Error processing request. Try again later");
            setBeforeAccountData("Error processing request. Try again later");
        }

    }, [loggedToken]);

    // fetch account data
    useEffect(() =>
    {
        LoadAccountData();
    },[LoadAccountData])


    // logout
    const Logout = () =>
    {
       
        setTimeout(() => 
        {
            // clear the cookie value
            WrapJsCookies.clearCookie('authconstant');
            // set is logged to false
            setLoggedToken('');

        }, 2000);
    }

    // update values
    const UpdateAccountData = async(propertyValue) =>
    {
        let UpdateData;

        if(propertyValue === "phoneNumber")
        {
            UpdateData  = {propertyValue: propertyValue, newValue: newPhoneNumber, authToken: loggedToken};
        }
        else
        {
            let newName = newFirstName + ":" + newLastName;
            UpdateData  = {propertyValue: propertyValue, newValue: newName, authToken: loggedToken};
        }

        // send to the backend for processing
        let UpdateAccount = await TransferRequests('updateaccount', UpdateData);

        if(UpdateAccount.isValidJson === true)
        {
            // show the response
            triggerToast(UpdateAccount.responseBody[1]);

            if(UpdateAccount.responseBody[0] === true)
            {
                // reload account
                LoadAccountData();
                setNewFirstName('');
                setNewLastName('');
                setNewPhoneNumber('');
            }

        }
        else
        {
            triggerToast("Error processing request. Try again later");
        }

    }

    // render
    return(

        <div className={`w-full max-w-[300px] bg-semi-dark h-full ${isAccountOpen? 'absolute': 'hidden'} flex flex-col right-0 shadow-bs-3 overflow-y-auto z-30`}>

            {/* toggle */}
            <div className="w-full flex flex-row justify-between items-center p-3 text-md">
                {/* close the account sidebar */}
                <FontAwesomeIcon icon="fas fa-arrow-left" onClick={() => ShowAccount()} className="cursor-pointer" />

                {/* logout */}
                <FontAwesomeIcon 
                    icon="fas fa-right-from-bracket" 
                    className="text-red-400 cursor-pointer" 
                    onClick={() => Logout()} 
                />
            </div>
            {/* account data */}
            {
                showAccountData? 
                (
                    <div className="flex-1 flex flex-col p-3 text-xs text-gray-200 font-roboto">
                    {/* stats */}
                    {
                        accountData.map((element, index) => 
                        {
                            const key       = Object.keys(element)[0];
                            const value     = element[key][0];
                            const icon      = element[key][1];
                            const texts     = element[key][2];
                            const updates   = element[key][3];
                    
                            return (
                                <div className="w-full" key={index}>
                                    <p className="mb-2 font-poppins">{texts}</p>
                                    <div  className="w-full mb-8 flex flex-row items-baseline">
                                        <FontAwesomeIcon icon={`fas fa-${icon}`} />
                                        <div className="flex-1 ml-3">
                                            <p className="text-gray-400 text-xs">{value}</p>
                                            {
                                                updates &&
                                                (
                                                    icon === "phone"?
                                                    (
                                                        //update the phone number
                                                        <div className="w-full flex flex-row justify-between mt-2">
                                                            <input 
                                                                type="number" 
                                                                className="flex-1 outline-none border-none p-2 bg-dark-pallete text-xs" 
                                                                style={{border: '1px solid darkslateblue'}}
                                                                placeholder="New Phone"
                                                                value={newPhoneNumber}
                                                                onChange={(e) => setNewPhoneNumber(e.target.value)}
                                                            />
                                                            {/* update */}
                                                            <div className="bg-semi-dark ml-2 h-[40px] w-[40px] cursor-pointer flex justify-center items-center shadow-bs-5 rounded-full z-10">
                                                                <FontAwesomeIcon 
                                                                    icon="pen" 
                                                                    size="lg" 
                                                                    onClick={() => UpdateAccountData('phoneNumber')}
                                                                />
                                                            </div>
                                                        </div>
                                                    ):
                                                    (
                                                        //update the value
                                                    <div className="w-full flex flex-row justify-between mt-2">
                                                        <div className="w-full space-y-2">
                                                            <input 
                                                                type="text" 
                                                                className="w-full outline-none border-none p-2 bg-dark-pallete text-xs" 
                                                                style={{border: '1px solid darkslateblue'}}
                                                                placeholder="First name"
                                                                value={newFirstName}
                                                                onChange={(e) => setNewFirstName(e.target.value)}
                                                            />
                                                            <input 
                                                                type="text" 
                                                                className="w-full outline-none border-none p-2 bg-dark-pallete text-xs" 
                                                                style={{border: '1px solid darkslateblue'}}
                                                                placeholder="Last name"
                                                                value={newLastName}
                                                                onChange={(e) => setNewLastName(e.target.value)}
                                                            />
                                                        </div>
                                                        {/* update */}
                                                        <div className="bg-semi-dark ml-2 min-h-[40px] w-[40px] cursor-pointer flex justify-center items-center shadow-bs-5 rounded-full z-10">
                                                            <FontAwesomeIcon 
                                                                icon="pen" 
                                                                size="lg" 
                                                                onClick={() => UpdateAccountData('fullName')}
                                                            />
                                                        </div>
                                                    </div>
                                                    )
                                                    
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
                ):
                (
                    <div className="flex-1 flex flex-col justify-center items-center">
                        <p className={`text-sm ${!fetchedAccount? 'animate-pulse': '' }`}>{beforeAccountData}</p>
                    </div>
                )
            }
        </div>
    );
}

export default Account;