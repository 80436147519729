import React, { useEffect, useState, useCallback } from "react";
import WrapJsCookies from "./auth/Cookies.jsx";
import Loading from "./auth/Loading";
import Login from "./auth/Login";
import Chat from "./auth/Chat";
import './resources/Icons.jsx';

function App() 
{
    // Function to trigger the toast with a message
    const [toastVisible, setToastVisible]   = useState(false);
    const [isLoading, setIsLoading]         = useState(true);
    const [toastMessage, setToastMessage]   = useState('');

    const [loggedToken, setLoggedToken]     = useState('');

    const triggerToast = useCallback((message) => 
    {
        setToastMessage(message);
        setToastVisible(true);
        setTimeout(() => 
        {
            setToastVisible(false);

        }, 5000);
    }, [setToastMessage, setToastVisible]);
    // Function to trigger the toast with a message

    // use effect to determine if the current user is logged in to their account
    useEffect(() =>
    {
        // check cookie
        let isAuthCookieCreated = WrapJsCookies.getCookie('authconstant');

        if (isAuthCookieCreated !== null) 
        {
            // show only the log in values
            setLoggedToken(isAuthCookieCreated);
        }

        setIsLoading(false);

    },[]);
    // use effect to determine if the current user is logged in to their account

    return (

        <div className="w-screen h-screen font-poppins bg-dark-pallete text-white relative tracking-wide">
            
            {
                isLoading?
                (
                    <Loading />
                ):
                (
                    // if logged logic
                    <>
                        {
                            loggedToken !== '' ? 
                            (
                                <Chat 
                                    triggerToast={triggerToast} 
                                    loggedToken={loggedToken}
                                    setLoggedToken={setLoggedToken}
                                />
                            ) : 
                            (
                                <Login 
                                    triggerToast={triggerToast} 
                                    setLoggedToken={setLoggedToken} 
                                />
                            )
                        }
                    </>
                )
            }

            {/* Toast component with CSS transition */}
            <div className={`fixed bottom-4 bg-dark-pallete right-4 z-50 text-white p-3 rounded max-w-[200px] ${toastVisible ? 'opacity-100' : 'opacity-0'}`} style={{ transition: 'opacity 0.5s ease-in-out' }}>
                <p className="text-xs font-mono">{toastMessage}</p>
            </div>

        </div>
    );
}

export default App;
