import Cookies from 'js-cookie';

// create cookies
function createCookie(cookieName, cookieValue) 
{
    Cookies.set(cookieName, cookieValue, { expires: 7 });
    return true;
}

// get cookie name
function getCookie(cookieName) 
{
    const name = Cookies.get(cookieName);
    return name !== undefined ? name : null;
}


// clear a cookie
function clearCookie(name) 
{
    Cookies.remove(name);
    return true;
}

function isAuthTokenOkay(token) 
{
    if (token.length !== 12 || token === "null" || token === "") 
    {
        return false;
    } 
    else 
    {
        return true;
    }
}

const WrapJsCookies = { createCookie, getCookie, clearCookie, isAuthTokenOkay };

export default WrapJsCookies;
