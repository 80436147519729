function Loading()
{
    return(

        <div className="w-full h-full flex flex-col justify-center items-center">
            <p className="text-md md:text-lg animate-pulse">Loading... Please wait</p>
        </div>
    );
}

export default Loading;