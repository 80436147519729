import React, { useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TransferRequests from "./TransferRequests";

function SendPrompt({loggedToken, currentThread, triggerToast})
{
    const [currentSendIcon, setCurrentSendIcon] = useState('send');
    const [newPrompt, setNewPrompt] = useState("");

    // handle uplaod prompt
    const UploadPrompt = async (e) =>
    {
        setCurrentSendIcon('wait');

        // upload the new value here
        if(newPrompt.replace(/\s+/g, '').length > 20)
        {
            // send
            let newPromptContainer  = 
            {
                authToken: loggedToken,
                newPrompt: newPrompt,
                threadId: currentThread
            };

            let AddPrompt = await TransferRequests('addprompt', newPromptContainer);
            setCurrentSendIcon('send');

            if(AddPrompt.isValidJson)
            {
                if(AddPrompt.responseBody[0] === true)
                {
                    // update the current to reload
                    setNewPrompt("");
                }
                else
                {
                    triggerToast(AddPrompt.responseBody[1]);
                }
            }
            else
            {
                triggerToast('Error processing request');
            }
        }
        else
        {
            setCurrentSendIcon('send');
            triggerToast('Empty or a very short prompt - a prompt must be at least 20 characters long');
        }
    }


    // render
    return(
        <div className="absolute bottom-0 w-full pt-2 flex flex-row-reverse items-center justify-between z-50">
            <div className="flex-1">
                <TextareaAutosize 
                    className="w-full bg-semi-dark shadow-bs-3 p-4 outline-none border-none text-xs rounded-lg resize-none md:text-sm" 
                    placeholder="Enter your text here"
                    onChange={(e) => setNewPrompt(e.target.value)}
                    value={newPrompt}
                />
            </div>

            {/* send the prompt */}
            <div 
                className="text-xl bg-semi-dark mr-2 h-[40px] w-[40px] mb-2 cursor-pointer flex justify-center items-center shadow-bs-5 rounded-full"
                onClick={() => UploadPrompt()}
            >
                <FontAwesomeIcon 
                    icon=
                    {
                        `${currentSendIcon === 'send'? 'fas fa-paper-plane': 'fas fa-spinner fa-spin'}`
                    } 
                    className=
                    {
                        `${currentSendIcon !== 'send'?'animate-spin': ''}`
                    } 
                />
            </div>
        </div>
    );
}

export default SendPrompt;