import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Sidebar from "./Sidebar";
import Account from "./Account";
import SendPrompt from "./SendPrompt";
import Toggle from "./Toggle";
import TransferRequests from "./TransferRequests";
import CopyToClipboard from "react-copy-to-clipboard";

function Chat({triggerToast, loggedToken, setLoggedToken})
{
    const [isSidebarOpen, setIsSidebarOpen]             = useState(false);
    const [isAccountOpen, setIsAccountOpen]             = useState(false);
    const [currentThread, setCurrentThread]             = useState("");
    const [isLoadedPrompts, setIsLoadedPrompts]         = useState(false);
    const [promptsDisplayText, setPromptsDisplayText]   = useState("Loading threads...");
    const [promptsData, setPromptsData]                 = useState([]);

    // any time the new thread is added, fetch its data here
    const LoadPromptsData = async() =>
    {
        // fetch the new chat value here
        if(currentThread === "")
        {
            setPromptsDisplayText("Please select a thread");
        }
        else
        {
            let promptsContainer = 
            {
                authToken: loggedToken,
                threadId: currentThread
            };
    
            try 
            {
                let LoadPrompts = await TransferRequests('threadprompts', promptsContainer);
    
                if (LoadPrompts.isValidJson === true) 
                {
                    if (LoadPrompts.responseBody[0] === true) 
                    {
                        setPromptsData(LoadPrompts.responseBody[1]);
                        setIsLoadedPrompts(true);
                    } 
                    else 
                    {
                        triggerToast(LoadPrompts.responseBody[1]);
                        setPromptsDisplayText(LoadPrompts.responseBody[1]);
                    }
                } 
                else 
                {
                    setPromptsDisplayText("Error processing request. Try again later");
                }
            } 
            catch (error) 
            {
                setPromptsDisplayText("Error processing request. Try again later");
            }
        }
    }

    //
    useEffect(() => 
    {
        const intervalId = setInterval(() => 
        {
            LoadPromptsData();

        }, 2000);
    
        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
    }, [currentThread]);

    // show the sidebar
    const ShowSidebar = () =>
    {
        if(isSidebarOpen)
        {
            // close sidebar
            setIsSidebarOpen(false);
        }
        else
        {
            // close account and open sidebar
            setIsAccountOpen(false);
            setIsSidebarOpen(true);
        }
    }

    // show account
    const ShowAccount = () =>
    {
        if(isAccountOpen)
        {
            // close account
            setIsAccountOpen(false);
        }
        else
        {
            // close sidebar and open account
            setIsSidebarOpen(false);
            setIsAccountOpen(true);
        }
    }

    // render
    return(

        <div className="w-full h-full flex flex-col relative md:flex-row">
            
            {/* sidebar values and account */}
            <Sidebar 
                isSidebarOpen={isSidebarOpen} 
                ShowSidebar={ShowSidebar} 
                triggerToast={triggerToast} 
                loggedToken={loggedToken}
                setCurrentThread={setCurrentThread}
            />

            <Account 
                isAccountOpen={isAccountOpen} 
                ShowAccount={ShowAccount} 
                triggerToast={triggerToast} 
                loggedToken={loggedToken}
                setLoggedToken={setLoggedToken}
            />
            
            {/* chat */}
            {/* set opacity whenever any of the bars is open */}
            <div className="w-full h-full flex p-3 flex-col max-w-[700px] mx-auto">

                <div className="w-full h-full flex flex-col relative">
                    {/* toggle menu */}
                    <Toggle 
                        ShowSidebar={ShowSidebar} 
                        ShowAccount={ShowAccount} 
                        loggedToken={loggedToken}
                        triggerToast={triggerToast} 
                        setCurrentThread={setCurrentThread}
                    />
                    
                    {/* chats area */}
                    {
                        isLoadedPrompts?
                        (
                            <div className="flex-1 overflow-y-auto text-xs">
                                {/* about the thread */}
                                <p className="font-semibold text-gray-300">{promptsData.thread.threadName}</p>

                                {/* show all the responses associated with this value */}
                                {/* single entry */}
                                {
                                    promptsData.prompts.length > 0 ?
                                    (
                                        promptsData.prompts.map((prompt, index) =>
                                        (
                                            <div key={index} className="w-full mt-5 mb-8 pr-3 font-rubik text-gray-300 flex flex-row">
                                                {/* show icon */}
                                                <div className="w-auto pr-2">
                                                    <FontAwesomeIcon icon="fas fa-pen-to-square" size="lg"/>
                                                </div>

                                                <div className="flex-1">
                                                    {/* <p className="mb-3" dangerouslySetInnerHTML={{ __html:  }}></p> */}
                                                    <p className="mb-3">{prompt.promptText}</p>
                                                    <div className="w-full mb-3 text-md">
                                                        {/* share this text */}
                                                        <CopyToClipboard 
                                                            text={`${prompt.promptText}`} 
                                                            onCopy={() => triggerToast('Copied to clipboard')}
                                                        >
                                                            <FontAwesomeIcon 
                                                                icon="far fa-clipboard" 
                                                                className="cursor-pointer" 
                                                                size="lg"
                                                            />
                                                        </CopyToClipboard>
                                                    </div>

                                                    <div className="w-full flex flex-row justify-between font-rubik">
                                                        <p 
                                                            className=
                                                            {
                                                                prompt.promptSentiment === null? "text-orange-400 capitalize" : 
                                                                prompt.promptSentiment === "happy"? "text-green-400 capitalize":
                                                                prompt.promptSentiment === "neutral"? "text-blue-400 capitalize":
                                                                "text-red-400 capitalize"
                                                            }
                                                        >
                                                            {prompt.promptSentiment === null? "Processing sentiment...": prompt.promptSentiment}
                                                        </p>
                                                        <p className="italic text-gray-400">- {prompt.dateRequested}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ):
                                    (
                                        <p className="text-center flex-1 mt-10">No prompts added yet</p>
                                    )
                                }
                            </div> 
                        ):
                        (
                            <div className="flex-1 flex flex-col justify-center items-center">
                                <p className="text-sm">{currentThread === "" ? "Please select a thread" : promptsDisplayText}</p>
                            </div>
                        )
                    }

                    {/* send prompt area */}
                    {
                        currentThread !== "" &&
                        (
                            <SendPrompt 
                                loggedToken={loggedToken} 
                                currentThread={currentThread}
                                triggerToast={triggerToast}
                            />
                        )
                    }
                    
                </div>
            </div>

        </div>
    );
}

export default Chat;