import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TransferRequests from "./TransferRequests";

function Toggle({ShowSidebar, ShowAccount, loggedToken,triggerToast,setCurrentThread})
{
    const [isResponsesShowing, setIsResponsesShowing]   = useState(false);
    const [errorMessage, setErrorMessage]   = useState('Please wait...');
    const [hasListItems, setHasListItems] = useState(false);
    const [listItems, setListItems] = useState([]);


    // fethc the prompts
    const FetchPrompts = async(subString) =>
    {
        // enable the response field
        setIsResponsesShowing(true);

        if (subString.length > 0) 
        {
            // fetch the like
            let SearchData  = 
            {
                authToken: loggedToken,
                searchString: subString
            };
            
            let ResetRequest = await TransferRequests('searchprompt', SearchData);

            if(ResetRequest.isValidJson === true)
            {
                // check if an error was returned
                if(ResetRequest.responseBody[0] === true)
                {
                    // check if the array has any data in it
                    setListItems(ResetRequest.responseBody[1]);
                    setHasListItems(true);
                }
                else
                {
                    setListItems([]);
                    setErrorMessage(ResetRequest.responseBody[1]);
                    setHasListItems(false);
                }
            }
            else
            {
                setListItems([]);
                setErrorMessage('Error processing');
                setHasListItems(false);
            }
        }
        else
        {
            setIsResponsesShowing(false);
        }
    }

    // render
    return(

        <div className="w-full pb-4 flex flex-row justify-between items-center text-lg md:text-xl">
            <FontAwesomeIcon icon="fas fa-bars" onClick={() => ShowSidebar()} className="cursor-pointer"/>

            {/* search functionality */}
            <div className="flex-1 px-4 flex flex-col">
                <input 
                    id="searchId"
                    type="text" 
                    className="text-xs p-2 outline-none border-none bg-semi-dark shadow-bs-5 flex-1 font-roboto" 
                    placeholder="Search here..." 
                    onChange={(e) => FetchPrompts(e.target.value)}
                />
                {
                    isResponsesShowing &&
                    (
                        <div className="relative w-full font-roboto">
                            <div className="absolute top-full left-0 right-0 bg-semi-dark shadow-bs-5 p-2 text-xs z-10">
                                {
                                    hasListItems?
                                    (
                                        listItems.map((promptData, index) =>
                                        (
                                            <p 
                                                key={index} 
                                                className="line-clamp-1 my-2 cursor-pointer"
                                                onClick={() => 
                                                {
                                                    triggerToast('Loading thread...'); 
                                                    setCurrentThread(promptData.threadIdentifier);
                                                    setErrorMessage('Please wait...');
                                                    setHasListItems(false);
                                                    setListItems([]);
                                                    setIsResponsesShowing(false);
                                                    document.getElementById('searchId').value = '';
                                                }}
                                            >
                                                { index+ 1 + ". " + promptData.threadName}
                                            </p>
                                        ))
                                    ):
                                    (
                                        <p>{errorMessage}</p>
                                    )
                                }
                            </div>
                        </div>
                    )
                }
            </div>
            
            <FontAwesomeIcon icon="fas fa-user-plus" onClick={() => ShowAccount()} className="cursor-pointer"/>
        </div>
    );
}

export default Toggle;