import React, { useState } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import TransferRequests from './TransferRequests';
import WrapJsCookies from './Cookies';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Login({triggerToast, setLoggedToken})
{
    const [currentMessage, setCurrentMessage]           = useState('Create a new account');
    const [currentFunction, setCurrentFunction]         = useState('signup');
    // manual signup
    const [signupFirstName, setSignupFirstName]         = useState('');
    const [signupLastName, setSignupLastName]           = useState('');
    const [signupPhoneNumber, setSignupPhoneNumber]     = useState('');
    const [signupEmailAddress, setSignupEmailAddress]   = useState('');
    // manual login
    const [loginEmailAddress, setLoginEmailAddress]     = useState('');
    const [loginPassword, setLoginPassword]             = useState('');
    // manual reset password
    const [resetEmailAddress, setResetEmailAddress]     = useState('');

    // google login
    const LoginUser = async (JWTData) =>
    {
        let encodedData     = JWTData.credential;
        const decodedData   = jwtDecode(encodedData);

        let firstName       = decodedData.given_name;
        let lastName        = decodedData.family_name;
        let emailAddress    = decodedData.email;
        let emailVerified   = decodedData.email_verified === true? 1 : 0;

        // send the data to the database for processing
        let userData        = 
        {
            firstName: firstName,
            lastName: lastName,
            emailAddress: emailAddress,
            emailVerified: emailVerified
        };

        let LoginSendRequest = await TransferRequests('loginuser', userData);

        if(LoginSendRequest.isValidJson)
        {
            if(LoginSendRequest.responseBody[0] === true)
            {
                // signin the user
                if(WrapJsCookies.createCookie('authconstant', LoginSendRequest.responseBody[2]))
                {
                    setLoggedToken(LoginSendRequest.responseBody[2]);
                }
                else
                {
                    triggerToast('Unidentified error');
                }                
            }
            else
            {
                triggerToast(LoginSendRequest.responseBody[1]);
            }
        }
        else
        {
            triggerToast('Error processing request');
        }
    }

    // on focus the new thread
    const focusNewThreadInput = (e) =>
    {
        e.target.style.border = '1px solid royalblue';
    }

    // manual signup
    const ManualSignup = async() =>
    {
        let SignupData        = 
        {
            firstName: signupFirstName,
            lastName: signupLastName,
            phoneNumber: signupPhoneNumber,
            emailAddress: signupEmailAddress
        };

        let SignupRequest = await TransferRequests('manualsignup', SignupData);

        if (SignupRequest.isValidJson === true) 
        {
            if (SignupRequest.responseBody[0] === true) 
            {
                // set the login with the value of the email
                setLoginEmailAddress(signupEmailAddress);
                // reset values
                setSignupFirstName('');
                setSignupLastName('');
                setSignupEmailAddress('');
                setSignupPhoneNumber('');
                // show login
                setCurrentFunction('login');
            }
            else
            {
                triggerToast(SignupRequest.responseBody[1]);
            }
        }
        else
        {
            triggerToast('Error processing request');
        }
    }

    // manual login
    const ManualLogin = async() =>
    {
        let LoginData        = 
        {
            emailAddress: loginEmailAddress,
            loginPassword: loginPassword
        };

        let LoginRequest = await TransferRequests('manuallogin', LoginData);

        if(LoginRequest.isValidJson)
        {
            triggerToast(LoginRequest.responseBody[1]);

            if(LoginRequest.responseBody[0] === true)
            {
                // signin the user
                if(WrapJsCookies.createCookie('authconstant', LoginRequest.responseBody[2]))
                {
                    setLoggedToken(LoginRequest.responseBody[2]);
                }
                else
                {
                    triggerToast('Unidentified error');
                }                
            }
        }
        else
        {
            triggerToast('Error processing request');
        }
    }

    // manual reset
    const ManualReset = async() =>
    {
        let ResetData        = 
        {
            emailAddress: resetEmailAddress
        };

        let ResetRequest = await TransferRequests('manualreset', ResetData);

        if(ResetRequest.isValidJson)
        {
            triggerToast(ResetRequest.responseBody[1]);

            if(ResetRequest.responseBody[0] === true)
            {
                // prepare the login   
                // set the login with the value of the email
                setLoginEmailAddress(resetEmailAddress);
                // empty reset
                setResetEmailAddress('');
                // show login
                setCurrentFunction('login');
            }
        }
        else
        {
            triggerToast('Error processing request');
        }
    }

    // render
    return(

        <div className="w-full h-full text-sm md:text-md">
            <div className='w-full h-full text-center p-2 max-w-[720px] mx-auto space-y-14 overflow-y-auto'>
                <p className="text-md sm:text-2xl md:text-4xl font-bold uppercase mt-24">Welcome to our world!</p>
                <p className="text-center">Discover the power of text sentiment analysis with our cutting-edge platform. Analyze and understand emotions behind the words, whether it's for customer feedback, social media, or any text data. Turn raw text into actionable insights effortlessly.</p>

                <div className='w-full my-10 max-w-[400px] flex flex-row justify-center mx-auto'>
                    <GoogleLogin
                        onSuccess={credentialResponse => 
                        {
                            LoginUser(credentialResponse)
                        }}
                        onError={() => 
                        {
                            triggerToast('Login Failed');
                        }}
                    />
                </div>
                
                <p>OR</p>

                <div className='w-full text-xs max-w-[400px] bg-semi-dark shadow-bs-2 p-2 mx-auto'>
                    <p className='text-start mb-4'>{currentMessage}</p>
                    
                    {
                        currentFunction === "signup" ?
                        (
                            <div className='w-full space-y-3'>
                                <input 
                                    type="text" 
                                    onFocus={focusNewThreadInput} 
                                    className="w-full outline-none border-none p-2 bg-dark-pallete text-xs" 
                                    style={{border: '1px solid darkslateblue'}}
                                    placeholder='First Name'
                                    value={signupFirstName}
                                    onChange={(e) => setSignupFirstName(e.target.value)}
                                />
                                <input 
                                    type="text" 
                                    onFocus={focusNewThreadInput} 
                                    className="w-full outline-none border-none p-2 bg-dark-pallete text-xs" 
                                    style={{border: '1px solid darkslateblue'}}
                                    placeholder='Last Name'
                                    value={signupLastName}
                                    onChange={(e) => setSignupLastName(e.target.value)}
                                />
                                <input 
                                    type="email" 
                                    onFocus={focusNewThreadInput} 
                                    className="w-full outline-none border-none p-2 bg-dark-pallete text-xs" 
                                    style={{border: '1px solid darkslateblue'}}
                                    placeholder='Email Address'
                                    value={signupEmailAddress}
                                    onChange={(e) => setSignupEmailAddress(e.target.value)}
                                />
                                <input 
                                    type="number" 
                                    onFocus={focusNewThreadInput} 
                                    className="w-full outline-none border-none p-2 bg-dark-pallete text-xs" 
                                    style={{border: '1px solid darkslateblue'}}
                                    placeholder='Phone Number'
                                    value={signupPhoneNumber}
                                    onChange={(e) => setSignupPhoneNumber(e.target.value)}
                                />
                                <button type="button" className="w-full bg-main-blue py-2" onClick={() => ManualSignup()}>
                                    <FontAwesomeIcon icon="user-plus" className="mr-2"/>   
                                    Signup
                                </button>
                            </div>
                        ):
                        (
                            currentFunction === "login" ?
                            (
                                <div className='w-full space-y-3'>
                                    <input 
                                        type="email" 
                                        onFocus={focusNewThreadInput} 
                                        className="w-full outline-none border-none p-2 bg-dark-pallete text-xs" 
                                        style={{border: '1px solid darkslateblue'}}
                                        placeholder='Email Address'
                                        value={loginEmailAddress}
                                        onChange={(e) => setLoginEmailAddress(e.target.value)}
                                    />
                                    <input 
                                        type="password" 
                                        onFocus={focusNewThreadInput} 
                                        className="w-full outline-none border-none p-2 bg-dark-pallete text-xs" 
                                        style={{border: '1px solid darkslateblue'}}
                                        placeholder='Password'
                                        value={loginPassword}
                                        onChange={(e) => setLoginPassword(e.target.value)}
                                    />
                                    <button type="button" className="w-full bg-main-blue py-2" onClick={() => ManualLogin()}>
                                        <FontAwesomeIcon icon="lock" className="mr-2"/>   
                                        Login
                                    </button>
                                </div>
                            ):
                            (
                                <div className='w-full space-y-3'>
                                    <input 
                                        type="email" 
                                        onFocus={focusNewThreadInput} 
                                        className="w-full outline-none border-none p-2 bg-dark-pallete text-xs" 
                                        style={{border: '1px solid darkslateblue'}}
                                        placeholder='Email Address'
                                        value={resetEmailAddress}
                                        onChange={(e) => setResetEmailAddress(e.target.value)}
                                    />
                                    <button type="button" className="w-full bg-main-blue py-2" onClick={() => ManualReset()}>
                                        <FontAwesomeIcon icon="cog" className="mr-2"/>   
                                        Reset
                                    </button>
                                </div>
                            )
                        )

                    }

                    <div className='w-full flex flex-row justify-around items-center mt-10 text-slate-300'>
                        <p 
                            className='cursor-pointer' 
                            onClick={() => 
                            {
                                setCurrentMessage('Login')
                                setCurrentFunction('login');
                            }}
                        >Login?</p>
                        <p 
                            className='cursor-pointer' 
                            onClick={() => 
                            {
                                setCurrentMessage('Reset Password')
                                setCurrentFunction('reset');
                            }}
                        >Reset Password?</p>
                    </div>
                </div>

            </div>

        </div>
    );
}

export default Login;