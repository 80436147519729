import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { GoogleOAuthProvider } from '@react-oauth/google';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <GoogleOAuthProvider clientId="1067772146100-16v4ri9go92ssts4kbhr3k06i8nt1kt4.apps.googleusercontent.com">
        <App />
    </GoogleOAuthProvider>
);

