import React, { useCallback ,useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WrapJsCookies from "./Cookies";
import TransferRequests from "./TransferRequests";

function Sidebar({isSidebarOpen, ShowSidebar, triggerToast, loggedToken, setCurrentThread})
{
    const [isNewThread, setIsNewThread] = useState(true);
    const [newThreadValue, setNewThreadValue] = useState("");
    const [isLoadingAddThread, setIsLoadingAddThread] = useState("pen-to-square");
    const [selectedFilter, setSelectedFilter] = useState("recent");

    const [isThreadsLoading, setIsThreadsLoading] = useState(true);
    const [threadsDisplayText, setThreadsDisplayText] = useState('loading');
    const [threadsContainer, setThreadsContainer] = useState([]);

    // handle select filter change
    const handleChange = (e) => 
    {
        setSelectedFilter(e.target.value);
    };

    const currentDate = new Date();

    const months = 
    [
        "January", "February", "March",
        "April","May", "June", 
        "July", "August","September", 
        "October", "November", "December"
    ];

    // toggle new thread
    const ToggleShowThread = () =>
    {
        setIsNewThread(!isNewThread);
    }
    // on focus the new thread
    const focusNewThreadInput = (e) =>
    {
        e.target.style.border = '1px solid royalblue';
    }

    // create a new thread
    const CreateNewThread = async (e) =>
    {
        // change icon
        setIsLoadingAddThread('spinner');
        e.target.disabled       = true;
        e.target.style.opacity  = 0.7;

        if (newThreadValue.replace(/\s+/g, '').length > 10 && newThreadValue.replace(/\s+/g, '').length < 40) 
        {
            if(WrapJsCookies.isAuthTokenOkay(loggedToken))
            {
                // send the request to the endpoint
                let threadsData  = 
                {
                    authToken: loggedToken,
                    newThread: newThreadValue
                };

                let AddThread = await TransferRequests('addthread', threadsData);

                if(AddThread.isValidJson)
                {
                    triggerToast(AddThread.responseBody[1]);

                    if (AddThread.responseBody[0] === true) 
                    {
                        setIsLoadingAddThread('pen-to-square');
                        triggerToast(AddThread.responseBody[1]);

                        setNewThreadValue("");
                        e.target.disabled       = false;
                        e.target.style.opacity  = 1;

                        setTimeout(() => 
                        {
                            // go to the new value file
                            setCurrentThread(AddThread.responseBody[2]);

                        }, 5000);
                    }
                }
                else
                {
                    triggerToast('Error processing request');
                }
            }
            else
            {
                triggerToast('Please logout and try again');
                setIsLoadingAddThread('pen-to-square');
                e.target.disabled       = false;
                e.target.style.opacity  = 1;
            }
        }
        else
        {
            triggerToast('Thread name must be between 10 and 40 characters');
            setIsLoadingAddThread('pen-to-square');
            e.target.disabled       = false;
            e.target.style.opacity  = 1;
        }
    }

    // load the threads on mount
    const LoadSidebarThreads = useCallback(async () => 
    {
        let threadsCollection = 
        {
            authToken: loggedToken,
            sortBy: selectedFilter,
        };

        let LoadThreads = await TransferRequests('accountthreads', threadsCollection);

        if (LoadThreads.isValidJson) 
        {
            if(LoadThreads.responseBody[0] === true)
            {
                // check if there is at least a single value
                setThreadsContainer(LoadThreads.responseBody[2]);
                setIsThreadsLoading(false);
            }
            else
            {
                setThreadsDisplayText(LoadThreads.responseBody[1]);
            }
        } 
        else 
        {
            setThreadsDisplayText("Error processing request. Try again later");
        }
    }, [loggedToken, selectedFilter]);

    useEffect(() => 
    {
        // Load the threads on mount and when selectedFilter changes
        LoadSidebarThreads();
    }, [LoadSidebarThreads]);

    // delete thread
    const DeleteThread = async(threadIdentifier) =>
    {
        //prompt
        if (window.confirm('Do you really want to delete this thread?')) 
        {
            // collect the data
            let threadContainer = 
            {
                authToken: loggedToken,
                threadId: threadIdentifier
            };

            let DeleteThisThread = await TransferRequests('deletethread', threadContainer);

            if(DeleteThisThread.isValidJson === true)
            {
                // check the array status
                if(DeleteThisThread.responseBody[0] === true)
                {
                    await LoadSidebarThreads();
                }
                else
                {
                    triggerToast(DeleteThisThread.responseBody[1]);
                }
            }
            else
            {
                triggerToast('Error processing request');
            }
        }
    }

    // set the new thread
    const SetCurrentThread = (thread) =>
    {
        triggerToast('Loading thread...'); 
        setCurrentThread(thread);
        // hide the sidebar
        ShowSidebar();
    }

    // render
    return(
        <div className={`w-full max-w-[250px] bg-semi-dark h-full ${isSidebarOpen? 'absolute': 'hidden'} flex flex-col justify-between  shadow-bs-3 z-30 md:static`}>
            {/* toggle */}
            <div className="w-full flex flex-row justify-end p-3 text-md md:text-lg md:hidden">
                <FontAwesomeIcon icon="fas fa-bars" onClick={() => ShowSidebar()} />
            </div>
            
            {/* data */}
            <div className="flex-1 flex flex-col p-3">
                {/* sort by */}
                <div className="w-full flex flex-row items-center justify-end mb-4">
                    <select 
                        value={selectedFilter} 
                        onChange={handleChange} 
                        className="outline-none bg-inherit text-xs"
                    >
                        <optgroup label="Created Date" className="text-white bg-dark-pallete">
                            <option value="recent" className="text-white bg-dark-pallete">Recent</option>
                            <option value="oldest" className="text-white bg-dark-pallete">Oldest</option>
                        </optgroup>
                        <optgroup label="Alphabetically" className="text-white bg-dark-pallete">
                            <option value="ascending" className="text-white bg-dark-pallete">Ascending</option>
                            <option value="descending" className="text-white bg-dark-pallete">Descending</option>
                        </optgroup>
                    </select>
                </div>
                {/* new thread */}
                <div className="w-full text-xs my-2 md:text-sm">
                    <div className="w-full flex flex-row justify-between items-center">
                        <p>New Thread</p>
                        <FontAwesomeIcon 
                            icon="fas fa-pen-to-square" 
                            size="lg" 
                            className="mr-2 text-green-300" 
                            onClick={() => ToggleShowThread()}
                        />
                    </div>
                    <div className={`w-full mt-2 ${isNewThread? 'flex' : 'hidden'} flex-col space-y-3`}>
                        <input 
                            type="text" 
                            onFocus={focusNewThreadInput} 
                            className="w-full outline-none border-none p-2 bg-dark-pallete text-xs" 
                            style={{border: '1px solid darkslateblue'}}
                            onChange={(e) => setNewThreadValue(e.target.value)}
                            value={newThreadValue}
                        />
                        <button type="button" className="w-full bg-main-blue py-2" onClick={(e) => CreateNewThread(e)}>
                            <FontAwesomeIcon 
                                icon={`fas fa-${isLoadingAddThread}`} 
                                className={`mr-2 ${isLoadingAddThread === 'spinner'? 'animate-spin': ''}`} 
                            /> 
                            Create
                        </button>
                    </div>
                </div>
                
                {/* entries */}
                <div className="flex-1 relative text-xs flex">
                    {
                        isThreadsLoading?
                        (
                            <div className={`flex-1 flex justify-center items-center ${threadsDisplayText === 'loading'? 'animate-pulse': ''} `}>
                                {threadsDisplayText === 'loading'? 'Loading threads...': threadsDisplayText }
                            </div>
                        ):
                        (
                            <div className="absolute top-0 left-0 right-0 bottom-0 overflow-y-auto pr-3">
                                {
                                    Object.entries(threadsContainer).map(([date, threads]) => 
                                    (
                                        <div key={date} className="w-full">
                                            <p className="text-xs font-bold py-3 text-gray-200">{date}</p>
                                            {
                                                threads.map(thread => 
                                                (
                                                    <div className="w-full mb-2 flex flex-row justify-between cursor-pointer" key={thread.threadIdentifier}>
                                                        <p className="line-clamp-1 mr-3 mb-3 text-gray-300"  onClick={() => SetCurrentThread(thread.threadIdentifier)}>{thread.threadName}</p>
                                                        <FontAwesomeIcon 
                                                            icon="fas fa-trash-alt" 
                                                            className="text-red-500"
                                                            onClick={() => DeleteThread(thread.threadIdentifier)}
                                                        /> 
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                        )
                    }
                    
                </div>
                {/* show time */}
                <div className="w-full text-xs flex justify-center py-2 flex-col items-center font-rubik">
                    {/* <p>{months[currentDate.getMonth()] +" "+ currentDate.getDate() +", "+ currentDate.getFullYear()}</p> */}
                    <p>Contact Us:</p>
                    <div className="w-full flex flex-row justify-center space-x-4 mt-3">
                        <a href="https://facebook.com/groups/626320722706280/" className="cursor-pointer" target="_blank"><FontAwesomeIcon icon="fa-brands fa-facebook" size="lg"/></a>
                        <a href="https://chat.whatsapp.com/Eb1MnVVf3hY0ZPB27Gn4xY" className="cursor-pointer" target="_blank"><FontAwesomeIcon icon="fa-brands fa-whatsapp" size="lg"/></a>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Sidebar;